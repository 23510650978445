import {
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  InfoCircleOutlined,
  AuditOutlined
} from "@ant-design/icons";
import { Layout, Menu, Row } from "antd";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSideNavigationMenuStyles } from "./styles";
import { useUsers } from "../../../hooks/UserHooks";

const { Sider } = Layout;
const { SubMenu } = Menu;

/**
 * The side navigation menu
 */
const SideNavigationMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const { searchUsers } = useUsers();

  // State to track the currently selected key
  const [selectedKey, setSelectedKey] = useState(location.pathname);

  // Handle switching to different pages
  const onClick = ({ key }) => {
    if (key.startsWith("/")) {
      searchUsers("");
      setSelectedKey(key);
      history.push(key);
    }
  };

  const { sideBarContainerCls } = useSideNavigationMenuStyles();

  return (
    <Sider width={300} className={sideBarContainerCls}>
      <Row>
        <Menu
          defaultSelectedKeys={[selectedKey]}
          mode="inline"
          onClick={onClick}
          defaultOpenKeys={location.pathname.includes("/logs") ? ["/logs"] : []} // Keeps Logs submenu open if it's active
        >
          <Menu.Item icon={<UserOutlined />} key="/profile">
            My Profile
          </Menu.Item>
          <Menu.Item icon={<TeamOutlined />} key="/user-management">
            User Management
          </Menu.Item>
          <Menu.Item icon={<FileOutlined />} key="/dashboard">
            Usage Reports
          </Menu.Item>

          <SubMenu
            key="/logs"
            icon={<AuditOutlined />}
            title="Logs"
            defaultSelectedKeys={["/logs"]}
          >
            <Menu.Item key="/logs">Retrieval Logs</Menu.Item>
            <Menu.Item key="/logsau">Author Logs</Menu.Item>
          </SubMenu>
          {/* <Menu.Item icon={<InfoCircleOutlined />} key="/about">
            About
          </Menu.Item> */}
        </Menu>
      </Row>
    </Sider>
  );
};

export default SideNavigationMenu;
