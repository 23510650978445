import { Layout } from "antd";
import React from "react";
import SideNavigationMenu from "../../organisms/SideNavigationMenu/SideNavigationMenu";
import TopMenuNavigation from "../../organisms/TopMenuNavigation/TopMenuNavigation";
import { useTeamManagementPageStyles } from "./styles";

const { Content } = Layout;

/**
 * The about page
 */
const AboutPage = () => {
  const googleDocEmbedLink = 'https://docs.google.com/document/d/e/2PACX-1vR6hcuCt7CXo6VqTyBaHKdvPPYqfGklcrn8BUTtWm5WiMabcnTQkv2pEqNtmgHtyZO1nEzgydlJXtN8/pub?embedded=true'; // Replace this with your Google Docs embed link

  const { mainContentCls, titleCls } = useTeamManagementPageStyles();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <TopMenuNavigation />
      <Layout>
        <SideNavigationMenu />
        <Content className={mainContentCls}>
         <div>
         <iframe
            src={googleDocEmbedLink}
            title="Google Doc Viewer"
            style={{ border: 'none', width: '100%', height: '11000px' }}
            seamless
          />
         </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AboutPage;
