import { Layout, Row, Button, Modal, Table } from "antd";
import React, { useEffect, useState, useRef } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import SideNavigationMenu from "../../organisms/SideNavigationMenu/SideNavigationMenu";
import TopMenuNavigation from "../../organisms/TopMenuNavigation/TopMenuNavigation";
import { useDashboardPageStyles } from "./styles";
import { json2csv } from "json-2-csv";

const { Content } = Layout;

const LogDashboardPage = () => {
  const { mainContentCls } = useDashboardPageStyles();

  // References and State
  const dashboardDiv2 = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartData, setChartData] = useState([]); // Fetched chart data
  const [tableColumns, setTableColumns] = useState([]); // Table columns
  const [chartInstance, setChartInstance] = useState(null); // Single chart instance

  useEffect(() => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: "https://charts.mongodb.com/charts-vanoord-btabugz", // Replace with your MongoDB Charts base URL
    });

    // Create and render dashboard
    const dashboard = sdk.createDashboard({
      dashboardId: "11c94ef9-1077-4a81-9f3b-5789aa0a33db", // Replace with your dashboard ID
    });

    const chart = sdk.createChart({
      chartId: "beab5290-f4b2-408d-823f-8c5d696479a5", // Replace with your chart ID
    });

    async function renderCharts() {
      try {
        await dashboard.render(dashboardDiv2.current); // Render dashboard

        // Explicitly render the chart instance
        await chart.render(dashboardDiv2.current);
        setChartInstance(chart); // Save rendered chart instance
        console.log("Chart rendered and instance set.");
      } catch (err) {
        console.error("Error rendering charts:", err);
      }
    }

    renderCharts();
  }, []);

  const fetchDataFromSDK = async () => {
    try {
      if (chartInstance) {
        const data = await chartInstance.getData();
        console.log("Fetched chart data:", data); // This should show chart data in the console
        return data;
      } else {
        console.warn("Chart instance not ready.");
        return [];
      }
    } catch (error) {
      console.error("Error fetching chart data:", error);
      return [];
    }
  };

  const showModal = async () => {
    setIsModalOpen(true);
    try {
      const fetchedData = await fetchDataFromSDK(); // Fetch chart data
      console.log("Data fetched for modal:", fetchedData); // Check data here

      let processedData = [];
      if(fetchedData?.documents){
        processedData = fetchedData.documents.map((item, index) => ({...item, key: index.toString()}));
        console.log("Processed data:", processedData);
      } else if (Array.isArray(fetchedData)) {
         processedData = fetchedData.map((item, index) => ({...item, key: index.toString()}));
         console.log("Processed data:", processedData);
      }

      setChartData(processedData);

      // Generate table columns dynamically
      if (fetchedData?.fields) {
          const columns = Object.entries(fetchedData.fields).map(([key, value]) => ({
            title: value,
            dataIndex: key,
            key: key, // Ensure each column has a unique key
          }));
          setTableColumns(columns);
      } else if (processedData.length > 0) {
          // If fields are not provided, try to extract keys from the first object in the array
          const firstItem = processedData[0];
          const columns = Object.keys(firstItem).filter(key => key !== 'key').map(key => ({
              title: key,
              dataIndex: key,
              key: key
          }));
          setTableColumns(columns);
      }
      else {
        setTableColumns([]);
      }
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const downloadFile = (data, filename, type) => {
    const blob = new Blob([data], { type });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleDownloadCSV = async () => {
    try {
      if (chartData.length === 0 || tableColumns.length === 0) {
        console.warn("No data or columns available for CSV download.");
        return;
      }
  
      // Prepare the data for CSV conversion
      const csvData = chartData.map((row) => {
        const formattedRow = {};
        tableColumns.forEach((col) => {
          formattedRow[col.title] = row[col.dataIndex]; // Map data using column titles and data indexes
        });
        return formattedRow;
      });
  
      // Convert the data to CSV format
      const csv = await json2csv(csvData);
      downloadFile(csv, "chart_data.csv", "text/csv");
    } catch (error) {
      console.error("Error converting to CSV:", error);
    }
  };
  

  const handleDownloadJSON = () => {
    if (chartData.length === 0 || tableColumns.length === 0) {
      console.warn("No data or columns available for JSON download.");
      return;
    }
  
    // Prepare the data for JSON download
    const jsonData = chartData.map((row) => {
      const formattedRow = {};
      tableColumns.forEach((col) => {
        formattedRow[col.title] = row[col.dataIndex]; // Map data using column titles and data indexes
      });
      return formattedRow;
    });
  
    // Convert to JSON string with proper formatting
    const json = JSON.stringify(jsonData, null, 2);
    downloadFile(json, "chart_data.json", "application/json");
  };
  

  return (
    <Layout style={{ minHeight: "100vh", maxHeight: "3420px" }}>
      <TopMenuNavigation />
      <Layout>
        <SideNavigationMenu />
        <Content className={mainContentCls}>
          <Row align="middle" justify="end">
            <Button type="primary" onClick={showModal}>
              View Data
            </Button>
          </Row>
          <Row>
            <div
              ref={dashboardDiv2}
              style={{ width: "100%", height: "calc(80vh)", overflow: "hidden" }}
            />
            <div
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "22px",
                backgroundColor: "#f0f2f5",
                zIndex: 1,
              }}
            ></div>
          </Row>
        </Content>
      </Layout>
      <Modal
        title="Retrieval Logs"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Table columns={tableColumns} dataSource={chartData} rowKey="key" />
        <div
          style={{
            marginTop: 16,
            display: "flex",
            justifyContent: "flex-end",
            gap: 8,
          }}
        >
          <Button onClick={handleDownloadCSV}>Download CSV</Button>
          <Button onClick={handleDownloadJSON}>Download JSON</Button>
        </div>
      </Modal>
    </Layout>
  );
};

export default LogDashboardPage;